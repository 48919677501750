.about-carousel-section {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .about-section-title {
    margin-bottom: 15px;
    font-size: 24px;
    color: #333;
    font-weight: bold;
  }
  
  /* Container do carrossel */
  .about-carousel {
    position: relative;
    overflow: hidden;
  }
  
  /* Cada item do carrossel ocupa 100% da largura */
  .about-carousel-item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }
  
  .about-carousel-item.active {
    opacity: 1;
    position: relative;
  }
  
  /* Imagem do carrossel */
  .about-carousel-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  /* Título da imagem (sobreposto, no rodapé da imagem) */
  .about-image-title {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 0.9rem;
  }
  