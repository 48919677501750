.banner-admin {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    text-align: center;
}

.banner-admin h2 {
    color: #333;
    margin-bottom: 15px;
    font-size: 22px;
}

.banner-admin input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
}

.banner-admin button {
    background: #007bff;
    color: white;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 14px;
    transition: background 0.3s ease;
}

.banner-admin button:hover {
    background: #0056b3;
}

.banner-admin ul {
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
}

.banner-admin li {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #f8f8f8;
    padding: 10px;
    border-radius: 5px;
    width: 150px;
    height: 150px;
    overflow: hidden;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.banner-preview {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}

/* Botão Excluir posicionado no canto superior direito */
.banner-admin li button {
    position: absolute;
    top: 5px;
    right: 5px;
    background: rgba(255, 0, 0, 0.8);
    color: white;
    border: none;
    padding: 5px 8px;
    font-size: 12px;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease;
}

.banner-admin li button:hover {
    background: rgba(255, 0, 0, 1);
}
