/* Container principal */
.boxes-wrapper {
  padding: 50px 20px; /* Espaçamento generoso */
  background-color: #f5f5f5; /* Fundo claro e neutro */
}

/* Seção */
.boxes-section {
  margin-bottom: 70px; /* Espaço entre seções */
  max-width: 1280px; /* Largura máxima para centralizar */
  margin-left: auto;
  margin-right: auto;
}

/* Título da seção */
.boxes-section-title {
  font-size: 2.25rem; /* Título grande e impactante */
  font-weight: 700;
  color: #222; /* Cor escura e elegante */
  text-align: center;
  margin-bottom: 40px;
  letter-spacing: 0.5px; /* Espaçamento sutil entre letras */
}

/* Grid dos boxes */
.boxes-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Grid responsivo */
  gap: 10px; /* Espaçamento entre os boxes */
  padding: 0 10px; /* Margem lateral leve */
}

/* Item individual (box) */
.box-item {
  background-color: #ffffff; /* Fundo branco para contraste */
  border-radius: 10px; /* Bordas arredondadas suaves */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.08); /* Sombra leve e moderna */
  padding: 25px; /* Padding interno perfeito */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  min-height: 360px; /* Altura mínima para consistência */
}

.box-item:hover {
  transform: translateY(-5px); /* Elevação sutil no hover */
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.12); /* Sombra mais intensa */
}

/* Imagem do box */
.box-image {
  width: 250px;
  height: 250px; /* Altura fixa para uniformidade */
  object-fit: cover; /* Mantém proporção da imagem */
  border-radius: 8px; /* Bordas arredondadas na imagem */
  margin-bottom: 0px; /* Espaço abaixo da imagem */
}

/* Título do box */
.box-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 0px;
  line-height: 1.3; /* Melhor legibilidade */
}

/* Conteúdo do box */
.box-content {
  font-size: 1rem;
  color: #555; /* Cinza suave para texto */
  line-height: 1.4; /* Espaçamento para leitura confortável */
  margin-bottom: 0px;
  flex-grow: 1; /* Faz o conteúdo preencher o espaço disponível */
}

/* Responsividade para telas grandes (>= 1200px) */
@media (min-width: 1200px) {
  .boxes-grid {
    gap: 10px;
    grid-template-columns: repeat(3, 1fr); /* 3 colunas fixas */
  }

  .box-item {
    padding: 30px; /* Mais espaço em telas grandes */
  }

  .boxes-section-title {
    font-size: 2.5rem;
  }
}

/* Telas médias (768px - 1199px) */
@media (min-width: 768px) and (max-width: 1199px) {
  .boxes-grid {
    grid-template-columns: repeat(2, 1fr); /* 2 colunas */
    gap: 10px;
  }

  .box-item {
    padding: 20px;
  }

  .box-image {
    width: 220px;
    height: 220px;
  }

  .boxes-section-title {
    font-size: 2rem;
  }

  .box-title {
    font-size: 1.4rem;
  }
}

/* Telas pequenas (até 767px) */
@media (max-width: 767px) {
  .boxes-wrapper {
    padding: 30px 15px; /* Reduz padding em telas menores */
  }

  .boxes-grid {
    grid-template-columns: 1fr; /* 1 coluna */
    gap: 10px;
  }

  .box-item {
    padding: 20px;
    min-height: 340px; /* Altura ajustada */
  }

  .box-image {
    width: 200px;
    height: 200px;
  }

  .boxes-section-title {
    font-size: 1.75rem;
  }

  .box-title {
    font-size: 1.3rem;
  }

  .box-content {
    font-size: 0.95rem;
  }
}

/* Telas muito pequenas (até 480px) */
@media (max-width: 480px) {
  .boxes-wrapper {
    padding: 20px 10px; /* Padding mínimo */
  }

  .boxes-grid {
    gap: 5px;
  }

  .box-item {
    padding: 15px;
    min-height: 300px;
  }

  .box-image {
    width: 200px;
    height: 200px;
  }

  .boxes-section-title {
    font-size: 1.5rem;
  }

  .box-title {
    font-size: 1.2rem;
  }

  .box-content {
    font-size: 0.9rem;
  }
}