/* Estilos gerais */
.edit-products {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  font-family: 'Roboto', sans-serif;
  background-color: #f9f9f9;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.edit-products h2 {
  font-size: 28px;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
  font-weight: 700;
}

.error {
  color: #ff4d4d;
  background-color: #ffe5e5;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 15px;
  text-align: center;
}

.success {
  color: #28a745;
  background-color: #e8f5e9;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 15px;
  text-align: center;
}

/* Formulário de Categoria */
.add-category-form {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.add-category-form input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 14px;
  transition: border-color 0.3s ease;
}

.add-category-form input:focus {
  border-color: #007bff;
  outline: none;
}

.add-category-form button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.add-category-form button:hover {
  background-color: #0056b3;
}

/* Lista de Categorias */
.categories-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.category {
  background-color: #fff;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.category-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.category h3 {
  font-size: 22px;
  color: #333;
  margin: 0;
}

.category-buttons {
  display: flex;
  gap: 10px;
}

.category button {
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.category button:hover {
  background-color: #0056b3;
}

.delete-category-btn {
  background-color: #ff4d4d;
}

.delete-category-btn:hover {
  background-color: #cc0000;
}

/* Formulário de Produto */
.add-product-form,
.edit-product-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 20px;
}

.add-product-form input,
.add-product-form textarea,
.edit-product-form input,
.edit-product-form textarea {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 14px;
  transition: border-color 0.3s ease;
}

.add-product-form input:focus,
.add-product-form textarea:focus,
.edit-product-form input:focus,
.edit-product-form textarea:focus {
  border-color: #007bff;
  outline: none;
}

.add-product-form textarea,
.edit-product-form textarea {
  height: 100px;
  resize: vertical;
}

.add-product-form button,
.edit-product-form button {
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.add-product-form button:hover,
.edit-product-form button:hover {
  background-color: #218838;
}

/* Grade de Produtos */
.products-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.product-item {
  background-color: #fff;
  border-radius: 12px;
  padding: 15px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.product-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.image-container {
  position: relative;
}

.product-item img {
  width: 100%;
  height: 180px;
  object-fit: cover;
  border-radius: 8px;
}

.discount-tag {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #ff4d4d;
  color: white;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 12px;
  font-weight: bold;
}

.product-preview h4 {
  font-size: 18px;
  color: #333;
  margin: 10px 0;
}

.product-preview p {
  font-size: 14px;
  color: #666;
  margin: 5px 0;
}

.product-buttons {
  display: flex;
  gap: 10px;
  margin-top: 15px;
}

.product-buttons button {
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.product-buttons button:hover {
  background-color: #0056b3;
}

/* Botão Ver Mais */
.see-more-btn {
  display: block;
  margin: 20px auto 0;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.see-more-btn:hover {
  background-color: #0056b3;
}

/* Botão Salvar Tudo */
.save-all-btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 12px 24px;
  background-color: #25d366;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.save-all-btn:hover {
  background-color: #1ebe57;
}

/* Responsividade */
@media (max-width: 768px) {
  .products-grid {
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  }

  .product-item img {
    height: 150px;
  }

  .product-preview h4 {
    font-size: 16px;
  }

  .product-preview p {
    font-size: 12px;
  }

  .save-all-btn {
    padding: 10px 20px;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .products-grid {
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
  }

  .product-item img {
    height: 120px;
  }

  .product-preview h4 {
    font-size: 14px;
  }

  .product-preview p {
    font-size: 10px;
  }

  .save-all-btn {
    padding: 8px 16px;
    font-size: 12px;
  }
}