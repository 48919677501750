.products-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .products-container h1 {
    font-size: 28px;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .search-bar {
    margin-bottom: 20px;
  }
  
  .search-bar input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-sizing: border-box;
    background-color: #fff;
    color: #333;
    transition: border-color 0.3s ease-in-out;
  }
  
  .search-bar input:focus {
    border-color: #007bff;
    outline: none;
  }
  
  .products-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .category-section {
    margin-bottom: 30px;
  }
  
  .category-section h2 {
    font-size: 22px;
    color: #333;
    margin-bottom: 15px;
    text-align: left;
  }
  
  .product-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
  }
  
  .product-item-link {
    text-decoration: none;
    color: inherit;
  }
  
  .product-item {
    border: 1px solid #ddd;
    padding: 15px;
    border-radius: 8px;
    background-color: white;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease-in-out;
  }
  
  .product-item:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }
  
  .product-image {
    max-width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 8px;
    transition: transform 0.3s ease-in-out;
  }
  
  .product-item:hover .product-image {
    transform: scale(1.05);
  }
  
  .product-item p {
    font-size: 14px;
    color: #666;
    margin: 5px 0;
  }
  
  .see-more-btn {
    display: block;
    margin: 15px auto 0;
    padding: 8px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s, transform 0.2s ease-in-out;
  }
  
  .see-more-btn:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
  }
  
  @media (max-width: 768px) {
    .product-grid {
      grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    }
  
    .product-image {
      height: 120px;
    }
  }
  
  @media (max-width: 480px) {
    .product-grid {
      grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
    }
  
    .product-image {
      height: 100px;
    }
  }