.admin-whatsapp-config {
    max-width: 500px;
    margin: 2rem auto;
    padding: 2rem;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .admin-whatsapp-config h2 {
    text-align: center;
    margin-bottom: 1.5rem;
    color: #333;
  }
  
  .admin-whatsapp-config form {
    display: flex;
    flex-direction: column;
  }
  
  .admin-whatsapp-config label {
    margin-bottom: 0.5rem;
    font-weight: bold;
    color: #555;
  }
  
  .admin-whatsapp-config input[type="text"] {
    padding: 0.75rem;
    margin-bottom: 1.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
  }
  
  .admin-whatsapp-config button {
    padding: 0.75rem;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .admin-whatsapp-config button:hover {
    background-color: #0056b3;
  }
  
  .admin-whatsapp-config button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  .admin-whatsapp-config .error {
    color: #d8000c;
    background-color: #ffbaba;
    padding: 0.5rem;
    border-radius: 4px;
    text-align: center;
    margin-bottom: 1rem;
  }
  