.edit-about {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.edit-about h2 {
  text-align: center;
  margin-bottom: 20px;
}

.edit-about label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
}

.edit-about textarea,
.edit-about input {
  width: 100%;
  padding: 10px;
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

.edit-about textarea {
  height: 120px;
  resize: vertical;
}

.carousel-preview {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
}

.carousel-preview-item {
  position: relative;
  width: 200px;
}

.carousel-preview-item img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.carousel-preview-item p {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 0.9rem;
  text-align: center;
}

.carousel-preview-item button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: red;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 0.9rem;
}

.edit-about button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

.edit-about button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.error {
  color: red;
  text-align: center;
  font-weight: bold;
  margin-top: 10px;
}

.success {
  color: green;
  text-align: center;
  font-weight: bold;
  margin-top: 10px;
}
.form-group input[type="file"] {
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
}
