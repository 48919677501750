/* src/pages/AboutPage/AboutPage.css */
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
  }
  .about-page {
    max-width: 900px;
    margin: 0 auto;
  }
  
  .about-page h1 {
    text-align: justify;

  }
  
  .description {
    font-size: 1.2rem;
    line-height: 1.6;
    margin: 30px;
    text-align: justify;
    color: #333;
  }
  