/* Header fixo estilo Magalu */
.lojinha-header {
    width: 100%;
    height: 80px;
    background-color: #e5e5e5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
  
    /* Adicionando borda arco-íris */
    border-bottom: 5px solid transparent;
    background-image: linear-gradient(to right, #e5e5e5, #e5e5e5),
                      linear-gradient(to right, #ff0000, #ff7300, #ffeb00, #00d100, #009dff, #6a00ff, #ff00c3);
    background-origin: border-box;
    background-clip: padding-box, border-box;
  }
  
  /* Logo da lojinha */
  .lojinha-logo {
    height: 60px;
    object-fit: contain;
  }
  
  /* Título centralizado */
  .lojinha-title {
    font-size: 26px;
    font-weight: bold;
    color: #222222; /* Branco para contraste */
    text-transform: uppercase;
    font-family: "Arial", sans-serif;
    flex: 1;
    text-align: center;
  }
  
  /* Responsividade */
  @media (max-width: 768px) {
    .lojinha-header {
      height: 80px;
      padding: 0 20px;
    }
  
    .lojinha-logo {
      height: 50px;
    }
  
    .lojinha-title {
      font-size: 18px;
    }
  }
  
  @media (max-width: 480px) {
    .lojinha-header {
      height: 80px;
      padding: 0 15px;
    }
  
    .lojinha-logo {
      height: 45px;
    }
  
    .lojinha-title {
      font-size: 15px;
      margin: 10px 45px 0 0;
    }
  }
  