.view-users-container {
    max-width: 800px;
    margin: 50px auto;
    padding: 20px;
    text-align: center;
  }
  
  .users-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .users-table th,
  .users-table td {
    padding: 10px;
    border: 1px solid #ccc;
  }
  
  .users-table th {
    background-color: #ff6f61;
    color: white;
  }
  
  .users-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }