/* Estilos gerais */
body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f5f5f5;
}

.edit-boxes {
  max-width: 900px;
  margin: 2rem auto;
  padding: 1.5rem;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
}

.edit-boxes h2 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 1.5rem;
  text-align: center;
  font-weight: 600;
}

/* Mensagens de erro e sucesso */
.error, .success {
  text-align: center;
  font-size: 0.95rem;
  margin: 1rem 0;
  padding: 0.75rem;
  border-radius: 8px;
  color: #fff;
}

.error {
  background-color: #e74c3c;
}

.success {
  background-color: #2ecc71;
}

/* Formulário de Adicionar Seção */
.add-section-form {
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.add-section-form input {
  width: 100%;
  padding: 0.75rem;
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  font-size: 1rem;
  transition: border-color 0.3s ease;
}

.add-section-form input:focus {
  border-color: #007bff;
  outline: none;
}

.add-section-form button {
  padding: 0.75rem 1.5rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.add-section-form button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.add-section-form button:hover:enabled {
  background-color: #0056b3;
}

/* Lista de Seções */
.sections-list {
  margin-top: 2rem;
}

.section {
  background-color: #f9f9f9;
  border-radius: 12px;
  margin-bottom: 1.5rem;
  padding: 1.5rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.section h3 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.section button {
  background-color: #f39c12;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.3s ease;
}

.section button:hover {
  background-color: #e67e22;
}

.delete-section-btn {
  background-color: #e74c3c;
  margin-top: 1rem;
}

.delete-section-btn:hover {
  background-color: #c0392b;
}

/* Formulário de Adicionar Box */
.add-box-form {
  margin-top: 1rem;
  padding: 1rem;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.add-box-form input,
.add-box-form textarea {
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  font-size: 1rem;
  transition: border-color 0.3s ease;
}

.add-box-form input:focus,
.add-box-form textarea:focus {
  border-color: #007bff;
  outline: none;
}

.add-box-form textarea {
  resize: vertical;
  min-height: 100px;
}

.add-box-form button {
  padding: 0.75rem 1.5rem;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.add-box-form button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.add-box-form button:hover:enabled {
  background-color: #218838;
}

/* Boxes */
.boxes {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  margin-top: 1.5rem;
}

.box {
  background-color: #fff;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  position: relative;
}

.box img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
}

.box h4 {
  font-size: 1.2rem;
  color: #333;
  margin-top: 1rem;
}

.box p {
  font-size: 0.95rem;
  color: #666;
  margin-top: 0.5rem;
}

.box button {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.box button:hover {
  background-color: #c82333;
}

/* Botão Salvar Tudo */
.save-all-btn {
  width: 100%;
  padding: 0.75rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 1.5rem;
  transition: background-color 0.3s ease;
}

.save-all-btn:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.save-all-btn:hover:enabled {
  background-color: #0056b3;
}

/* Responsividade */
@media (max-width: 768px) {
  .edit-boxes {
    padding: 1rem;
  }

  .boxes {
    grid-template-columns: 1fr;
  }
}