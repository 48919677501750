/* Estilos gerais do header */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  background-color: #e4a463;
  color: #fff;
  position: sticky;
  top: 0;
  z-index: 1000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

/* Logo */
.header-logo img {
  max-height: 60px;
  transition: transform 0.3s ease;
}

.header-logo img:hover {
  transform: scale(1.05);
}

/* Botão de menu hambúrguer */
.header-menu-toggle {
  width: 70px;
  box-sizing: border-box;
  background: none;
  border: none;
  font-size: 2rem;
  color: #fff;
  cursor: pointer;
  display: none; /* Oculto em telas grandes */
  z-index: 1001;
  padding: 10px;
}

/* Navegação */
.header-nav {
  display: flex;
  align-items: center;
}

/* Lista de navegação */
.header-nav-list {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

/* Itens do menu */
.header-nav-list li {
  margin: 0 15px;
}

/* Links do menu */
.header-nav-list li a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #fff;
  font-weight: 600;
  font-size: 1.1rem;
  padding: 10px 15px;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Ícones do menu */
.nav-icon {
  margin-right: 8px;
  font-size: 1.2rem;
}

/* Hover nos links */
.header-nav-list li a:hover {
  background-color: #007bff;
  color: #fff;
}

/* Menu responsivo para telas pequenas */
@media (max-width: 768px) {
  .header {
    padding: 15px 20px;
  }

  .header-menu-toggle {
    display: block; /* Exibe o botão de menu */
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .header-nav {
    position: fixed;
    top: 0;
    right: 0;
    width: 250px;
    height: 100vh;
    background-color: #e4a463;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
    flex-direction: column;
    justify-content: center;
    box-shadow: -2px 0 10px rgba(0, 0, 0, 0.3);
  }

  .header-nav.nav-open {
    transform: translateX(0);
  }

  .header-nav-list {
    flex-direction: column;
    width: 100%;
  }

  .header-nav-list li {
    margin: 10px 0;
    width: 100%;
  }

  .header-nav-list li a {
    font-size: 1.25rem;
    padding: 15px 20px;
    width: 100%;
    border-bottom: 1px solid #333;
    justify-content: flex-start;
  }

  .nav-icon {
    font-size: 1.5rem;
  }
}

/* Telas grandes (>= 769px) */
@media (min-width: 769px) {
  .header {
    padding: 20px 40px;
  }

  .header-menu-toggle {
    display: none;
  }

  .header-nav {
    gap: 20px;
  }

  .header-nav-list {
    flex-direction: row;
  }

  .header-nav-list li a {
    padding: 10px 20px;
  }
}

/* Telas muito grandes (>= 1200px) */
@media (min-width: 1200px) {
  .header {
    max-width: 100%;
    margin: 0 auto;
  }

  .header-nav-list li a {
    font-size: 1.2rem;
  }

  .nav-icon {
    font-size: 1.3rem;
  }
}

/* Telas muito pequenas (<= 480px) */
@media (max-width: 480px) {
  .header {
    padding: 10px 15px;
  }

  .header-logo img {
    max-height: 50px;
  }

  .header-nav {
    width: 200px;
  }

  .header-nav-list li a {
    font-size: 1rem;
    padding: 12px 15px;
  }

  .nav-icon {
    font-size: 1.25rem;
  }
}