.whatsapp-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #25d366;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease;
  }
  
  .whatsapp-button:hover {
    transform: scale(1.1);
  }
  
  .whatsapp-button img {
    width: 100%;
    height: 100%;
  }
  