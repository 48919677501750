/* Container principal da página de login */
.loja-login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(135deg, #758aa0, #3b5998);
  padding: 20px;
}

/* Caixa do login */
.loja-login-box {
  background: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  max-width: 400px;
  width: 100%;
}

/* Título */
.loja-login-box h2 {
  color: #333;
  font-size: 24px;
  margin-bottom: 20px;
}

/* Inputs */
.loja-login-box input {
  width: 100%;
  padding: 12px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  transition: 0.3s;
}

.loja-login-box input:focus {
  border-color: #3b5998;
  outline: none;
}

/* Botão */
.loja-login-box button {
  width: 100%;
  padding: 12px;
  background-color: #3b5998;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: 0.3s;
}

.loja-login-box button:hover {
  background-color: #2d4373;
}

/* Mensagem de erro */
.loja-error {
  color: red;
  font-size: 14px;
  margin-bottom: 10px;
}

/* Responsividade */
@media (max-width: 480px) {
  .loja-login-box {
    padding: 20px;
  }

  .loja-login-box h2 {
    font-size: 20px;
  }
}
