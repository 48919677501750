.edit-hours {
    max-width: 600px;
    margin: 0 auto;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .edit-hours h2 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
  }
  
  .hour-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  
  .hour-item span {
    font-weight: bold;
    width: 50%;
  }
  
  .hour-item input {
    width: 50%;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  