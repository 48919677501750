.edit-footer {
  max-width: 800px;
  margin: 40px auto;
  padding: 30px;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  font-family: "Inter", sans-serif;
}

.edit-footer h2 {
  font-size: 26px;
  font-weight: 600;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

/* Mensagem de erro */
.error {
  color: #d9534f;
  background: #f8d7da;
  padding: 10px;
  border-radius: 8px;
  text-align: center;
  margin-bottom: 15px;
  font-size: 14px;
}

/* Seção do formulário */
.form-section {
  margin-bottom: 25px;
}

.form-section h3 {
  font-size: 20px;
  font-weight: 600;
  color: #444;
  margin-bottom: 10px;
  border-bottom: 2px solid #007bff;
  padding-bottom: 5px;
}

.form-section label {
  display: block;
  font-weight: 500;
  margin-bottom: 6px;
  color: #333;
}

.form-section input,
.form-section textarea,
.form-section select {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 15px;
  transition: all 0.3s ease;
}

.form-section textarea {
  resize: none;
  height: 100px;
}

.form-section input:focus,
.form-section select:focus,
.form-section textarea:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.3);
  outline: none;
}

/* Pré-visualização da imagem */
.footer-logo-preview,
.social-icon-preview {
  max-width: 120px;
  height: auto;
  margin-top: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

/* Estilo das redes sociais */
.social-edit {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 15px;
}

.social-edit img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #ddd;
}

/* Botão de envio */
button[type="submit"] {
  width: 100%;
  padding: 14px;
  font-size: 16px;
  font-weight: 600;
  background: linear-gradient(135deg, #007bff, #0056b3);
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

button[type="submit"]:hover {
  background: linear-gradient(135deg, #0056b3, #003c80);
}

button[type="submit"]:disabled {
  background: #ccc;
  cursor: not-allowed;
}

/* Botão de exclusão da logo */
.delete-logo-btn {
  margin-top: 10px;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 500;
  background: #d9534f;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.delete-logo-btn:hover {
  background: #c9302c;
}

.delete-logo-btn:disabled {
  background: #cccccc;
  cursor: not-allowed;
}

/* Responsividade */
@media (max-width: 768px) {
  .edit-footer {
    padding: 20px;
  }

  .form-section h3 {
    font-size: 18px;
  }

  .form-section input,
  .form-section textarea,
  .form-section select {
    font-size: 14px;
  }

  .footer-logo-preview,
  .social-icon-preview {
    max-width: 100px;
  }

  button[type="submit"] {
    font-size: 14px;
  }

  .delete-logo-btn {
    font-size: 12px;
    padding: 6px 12px;
  }
}